import type { SlotProps } from '@radix-ui/react-slot';
import { Slot } from '@radix-ui/react-slot';
import type { HTMLMotionProps, MotionProps, SVGMotionProps } from 'framer-motion';
import { AnimatePresence, LayoutGroup, MotionConfig, motion } from 'framer-motion';
import type { ComponentProps, HTMLAttributes, ReactElement, ReactNode } from 'react';
import { Children, cloneElement, forwardRef } from 'react';
import type { NavLinkProps } from 'react-router';
import { NavLink } from 'react-router';

import type { BackButtonProps, CreateButtonProps } from '~/components/button';
import { BackButton, CreateButton } from '~/components/button';
import { SidebarOpener } from '~/features/layout/sidebar-opener';
import { cn } from '~/libs/utils';

export type HeaderProps = HTMLAttributes<HTMLDivElement>;

export const Header = forwardRef<HTMLDivElement, HeaderProps & HTMLMotionProps<'div'> & { id: string }>(
  ({ className, children, id, ...props }, ref) => (
    <MotionConfig
      transition={{
        duration: 0.15,
      }}
    >
      <LayoutGroup id={id}>
        <AnimatePresence presenceAffectsLayout mode='popLayout' initial={false}>
          <motion.header
            data-testid='header'
            data-layout='header-root'
            layoutId='header-root'
            className={cn(
              'mb-8 flex min-h-8 w-full justify-between border-0 border-b border-b-border font-bold not-last:border-b max-lg:pb-4 max-md:flex-col max-md:gap-4',
              className
            )}
            ref={ref}
            {...props}
          >
            {children}
          </motion.header>
        </AnimatePresence>
      </LayoutGroup>
    </MotionConfig>
  )
);
Header.displayName = 'Header';

export const HeaderContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & HTMLMotionProps<'div'>>(
  ({ className, ...props }, ref) => (
    <motion.div
      data-layout='header-content'
      className={cn('flex items-stretch gap-2', className)}
      ref={ref}
      {...props}
    />
  )
);
HeaderContent.displayName = 'HeaderContent';

export const HeaderIcons = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement> & HTMLMotionProps<'span'>>(
  ({ className, children, ...props }, ref) => {
    if (Children.count(children) === 1)
      return (
        <>
          <HeaderIcon>
            <SidebarOpener />
          </HeaderIcon>
          <HeaderIcon>
            {cloneElement(children as ReactElement<{ layoutId: string }>, { layoutId: 'header-logo' })}
          </HeaderIcon>
        </>
      );

    return (
      <motion.span
        data-layout='header-icons'
        layoutId='header-icons'
        className={cn('my-auto flex gap-1', className)}
        ref={ref}
        {...props}
      >
        <HeaderIcon>
          <SidebarOpener />
        </HeaderIcon>
        {Children.map(children, (child, index) => {
          if (index === 1)
            return (
              <HeaderIcon>
                {cloneElement(child as ReactElement<{ layoutId: string }>, { layoutId: 'header-logo' })}
              </HeaderIcon>
            );
          return <HeaderIcon>{child}</HeaderIcon>;
        })}
      </motion.span>
    );
  }
);
HeaderIcons.displayName = 'HeaderIcons';

const MotionSlot = motion.create(Slot);
export const HeaderIcon = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement> & HTMLMotionProps<'span'>>(
  ({ className, ...props }, ref) => (
    <MotionSlot
      data-layout='header-icon'
      layout='position'
      className={cn('my-auto size-6', className)}
      ref={ref}
      {...props}
    />
  )
);
HeaderIcon.displayName = 'HeaderIcon';

export interface Tab {
  id: string;
  icon?: ReactNode;
  href?: string;
  label: ReactNode;
  disabled?: boolean;
  end?: boolean;
}

const MotionNavLink = motion.create<ComponentProps<typeof NavLink>, 'a'>(NavLink);

export const HeaderTabs = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & HTMLMotionProps<'div'>>(
  ({ className, children, ...props }, ref) => (
    <motion.nav
      data-layout='header-tabs'
      layoutId='header-tabs'
      className={cn('mx-2 mt-auto flex gap-4', className)}
      ref={ref}
      {...props}
    >
      {Children.map(children, (child, index) => {
        if (child && typeof child === 'object' && 'props' in child) {
          return cloneElement(child as ReactElement<MotionProps['transition']>, {
            transition: { delay: index * 0.05 },
          });
        }
        return child;
      })}
    </motion.nav>
  )
);
HeaderTabs.displayName = 'HeaderTabs';

export const HeaderTabItem = forwardRef<
  HTMLAnchorElement,
  NavLinkProps & {
    disabled?: boolean;
  }
>(({ to, end, children, className, disabled, ...props }, ref) => (
  <MotionNavLink
    {...props}
    initial={{ y: 10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: 10, opacity: 0 }}
    className={cn(
      '[&.active]:text-primary/90 relative -mb-px flex min-h-fit items-baseline gap-3 pb-2 text-sm font-medium text-foreground transition-all before:absolute before:inset-0 before:origin-center before:scale-x-0 before:border-0 before:border-foreground hover:before:scale-x-100 hover:before:border-b hover:before:transition-transform data-[disabled=true]:pointer-events-none data-[disabled=true]:cursor-not-allowed data-[disabled=true]:border-0 data-[disabled=true]:text-muted-foreground lg:pb-3 [&.active]:hover:text-primary',
      className
    )}
    data-disabled={disabled}
    aria-disabled={disabled}
    to={to}
    end={end}
    ref={ref}
  >
    {
      // @ts-expect-error this is a correct render props
      ({ isActive }) => (
        <>
          {children}
          {isActive ? (
            <motion.div
              data-component-type='header-tab-underline'
              layoutId='underline'
              className='absolute inset-0 -mb-px border-0 border-b-2 border-primary'
            />
          ) : null}
        </>
      )
    }
  </MotionNavLink>
));
HeaderTabItem.displayName = 'HeaderTabItem';
export const HeaderTabItemIcon = forwardRef<HTMLElement, SlotProps>(({ className, ...props }, ref) => (
  <Slot className={cn('size-4', className)} {...props} ref={ref} />
));
HeaderTabItemIcon.displayName = 'HeaderTabItemIcon';
export const HeaderTabItemText = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement> & HTMLMotionProps<'span'>>(
  ({ ...props }, ref) => <span ref={ref} {...props} />
);
HeaderTabItemText.displayName = 'HeaderTabItemText';

export const HeaderText = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & HTMLMotionProps<'div'>>(
  ({ className, children, ...props }, ref) => (
    <motion.div
      data-layout='header-text'
      layoutId='header-text'
      layout='position'
      className={cn('my-1 flex flex-col', className)}
      ref={ref}
      {...props}
    >
      {children}
    </motion.div>
  )
);
HeaderText.displayName = 'HeaderText';

export const HeaderTitle = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement> & HTMLMotionProps<'h2'>>(
  ({ className, ...props }, ref) => (
    <motion.h2
      data-layout='header-title'
      layoutId='header-title'
      layout='position'
      className={cn('text-lg only:my-auto xl:text-2xl', className)}
      ref={ref}
      {...props}
    />
  )
);
HeaderTitle.displayName = 'HeaderTitle';

export const HeaderDescription = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement> & HTMLMotionProps<'span'>>(
  ({ className, children, ...props }, ref) => {
    if (!children) return null;
    return (
      <motion.span
        data-layout='header-description'
        layoutId='header-description'
        layout='position'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={cn(
          'overflow-hidden text-ellipsis text-sm font-normal italic text-gray-700 dark:text-gray-400',
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </motion.span>
    );
  }
);
HeaderDescription.displayName = 'HeaderDescription';
const MotionBackButton = motion.create(BackButton);
export const HeaderBackButton = forwardRef<
  HTMLAnchorElement,
  Omit<BackButtonProps & SVGMotionProps<typeof BackButton>, 'to'>
>(({ ...props }, ref) => (
  <MotionBackButton
    to='..'
    relative='path'
    initial={{ x: -10, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: -10, opacity: 0 }}
    layoutId='back-button'
    {...props}
    ref={ref}
  />
));
HeaderBackButton.displayName = 'HeaderBackButton';

const MotionCreateButton = motion.create(CreateButton);
export const HeaderCreateButton = forwardRef<
  HTMLAnchorElement,
  CreateButtonProps & SVGMotionProps<typeof CreateButton>
>(({ ...props }, ref) => (
  <MotionCreateButton
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    layoutId='create-button'
    {...props}
    ref={ref}
  />
));
HeaderCreateButton.displayName = 'HeaderCreateButton';
