import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from '~/libs/utils';

export type SectionProps = HTMLAttributes<HTMLDivElement>;

export const Section = forwardRef<HTMLDivElement, SectionProps>(({ className, ...props }, ref) => (
  <article
    data-component-type='section'
    className={cn(
      'flex w-full items-baseline justify-between border-0 border-b-border p-4 not-last:border-b not-last:border-dashed',
      className
    )}
    ref={ref}
    {...props}
  />
));
Section.displayName = 'Section';
