import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { LinkProps } from 'react-router';
import { Link } from 'react-router';

import type { ApplicationIconProps } from '~/components/application-icons';
import { ApplicationIcon } from '~/components/application-icons';
import { cn } from '~/libs/utils';

const ns = 'button' satisfies Ns;
export type BackButtonProps = Omit<ApplicationIconProps, 'icon'> & LinkProps;
export const BackButton = forwardRef<HTMLAnchorElement, BackButtonProps>(({ to = '.', className, ...props }, ref) => {
  const { t } = useTranslation(ns);
  return (
    <Link
      data-testid='back'
      aria-label={t('aria.back', 'Back')}
      to={to}
      className={cn('text-primary', className)}
      ref={ref}
      {...props}
    >
      <ApplicationIcon className='my-auto size-6' icon='left' />
    </Link>
  );
});
BackButton.displayName = 'BackButton';
