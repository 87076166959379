import { forwardRef } from 'react';

import type { ApplicationIconProps } from '~/components/application-icons';
import { ApplicationIcon } from '~/components/application-icons';
import { Button } from '~/components/ui/button';
import { cn } from '~/libs/utils';

import { useSidebar } from './use-sidebar';

export const SidebarOpener = forwardRef<HTMLElement, Omit<ApplicationIconProps, 'icon'>>(
  ({ className, ...props }, ref) => {
    const [open, setOpen] = useSidebar();
    return !open ? (
      <Button
        variant='ghost'
        onClick={() => {
          setOpen(true);
        }}
      >
        <ApplicationIcon
          {...props}
          icon='panel-open'
          ref={ref}
          className={cn('text-foreground', className, 'size-4')}
        />
      </Button>
    ) : null;
  }
);
SidebarOpener.displayName = 'SidebarOpener';
